import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image";

// import app components
import { Edges, Spacer, Textarea, BackgroundImage } from "components"
import * as theme from "theme"

import WaveAltIcon from "icons/flipped-wave-sm.svg"

const PostSingle = props => {
  const {
    data: {
      post: {
        title,
        acf: { image, text }
      },
      allWp: { nodes }
    }
  } = props

  const newsOptions = nodes[0].themeOptions.siteOptions.news

  return <>
    <HeroContainer>
      <HeroOverlay />
      <NewsMediaHero>
        <Edges size="lg">
          <Grid container>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              {newsOptions.headerTitle && (
                <Typography
                  variant="h1"
                  children={
                    newsOptions.headerTitle
                      ? newsOptions.headerTitle
                      : "News & Media"
                  }
                />
              )}
            </Grid>
          </Grid>
        </Edges>
      </NewsMediaHero>
      {newsOptions.postBackgroundImage && (
        <HeroImageContainer>
          <BackgroundImage image={newsOptions.postBackgroundImage} />
        </HeroImageContainer>
      )}
    </HeroContainer>
    <BreadcrumbContainer>
      <Edges size="lg">
        <WaveContainer>
          <WaveAltIcon />
        </WaveContainer>
        <Grid container>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={10}>
            <Breadcrumb>
              <Link to={"/news"}>Back to selections</Link>
            </Breadcrumb>
          </Grid>
        </Grid>
      </Edges>
    </BreadcrumbContainer>
    <Edges size="lg">
      <Spacer pt={{ xs: 40, sm: 70 }} />
      <CustomGridContainer container>
        <Grid item xs={0} md={2}></Grid>
        <Grid item md={5}>
          {title && (
            <Spacer mb={10}>
              <Typography
                variant="h3"
                component="h1"
                children={Parser(title)}
              />
            </Spacer>
          )}
          <StyledTextarea content={text} />
          <Spacer
            mt={{ xs: 30, sm: 60, md: 90 }}
            mb={{ xs: 30, sm: 60, md: 90 }}
          >
            <WaveAltIcon style={{ maxWidth: "100%" }} />
          </Spacer>
        </Grid>
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={12} md={4}>
          <Spacer mt={{ xs: 0, md: 40 }} mb={{ xs: 50, md: 0 }}>
            {image && (
              <>
                <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} />
                {image.caption && <Caption>{Parser(image.caption)}</Caption>}
              </>
            )}
          </Spacer>
        </Grid>
      </CustomGridContainer>
    </Edges>
  </>;
}

const StyledTextarea = styled(Textarea)`
  && {
  }
`

const CustomGridContainer = styled(Grid)``

const HeroContainer = styled.div`
  position: relative;
  height: 125px;
  padding: ${({ border }) => (border ? "20px" : 0)};

  @media (min-width: 600px) {
    height: 200px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: radial-gradient(
      33.94% 50% at 77.5% 120.75%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      28.09deg,
      #000000 14.29%,
      rgba(0, 0, 0, 0) 39.6%
    );
  }
`

const NewsMediaHero = styled.div`
  align-items: center;
  background: linear-gradient(28.09deg, #000000 14.29%, rgba(0, 0, 0, 0) 39.6%);
  backdrop-filter: blur(12px);
  bottom: 0;
  color: white;
  display: flex;
  height: 110px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;

  h1 {
    margin-bottom: 12px;
  }

  @media (min-width: 600px) {
    height: 170px;
  }
`

const HeroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  position: absolute;
  width: 100%;
  height: 110px;
  bottom: 0px;
  left: 0px;
  z-index: 1;

  @media (min-width: 768px) {
    height: 170px;
  }
`

const HeroImageContainer = styled.div`
  position: relative;
  height: 140px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 200px;
  }
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: -5px;

  svg {
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    top: 0px;
  }
`

const Caption = styled.div`
  border-bottom: 1px solid;
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
`

const BreadcrumbContainer = styled.div`
  background: radial-gradient(
    17.98% 717.28% at 14.84% -12.35%,
    #000000 41.89%,
    #252525 100%
  );
  color: white;
  padding: 20px 0px;
  position: relative;
  width: 100%;
`

const Breadcrumb = styled.div`
  text-transform: uppercase;

  a {
    color: white;
    padding: 5px 0px;
  }
`

export const CollectionQuery = graphql`query DefaultSinglePost($id: String!) {
  post: wpPost(id: {eq: $id}) {
    id
    title
    uri
    date
    acf {
      text
      image {
        altText
        caption
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
  allWp {
    nodes {
      themeOptions {
        siteOptions {
          news {
            headerTitle
            postBackgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default PostSingle
